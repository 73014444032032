.ant-dropdown-menu.ant-dropdown-menu-light.ant-dropdown-menu-root.ant-dropdown-menu-vertical {
  padding-top: 0px;
  padding-bottom: 0px;
}

.seq-no-padding > .ant-popover-content > .ant-popover-inner > .ant-popover-inner-content {
  padding: 0px;
}

/**Below here might be removeable once shared theme is recolored to seq green, check later**/
.ant-radio-checked .ant-radio-inner {
  border-color: #00a071 !important ;
}
.ant-radio-checked .ant-radio-inner:after {
  background-color: #00a071;
}
.ant-radio:hover .ant-radio-inner {
  border-color: #00a071;
}

@-webkit-keyframes grow {
  from {
    -webkit-transform: scale(0.9);
  }
  to {
    -webkit-transform: scale(1.1);
  }
}
@-moz-keyframes grow {
  from {
    -moz-transform: scale(0.9);
  }
  to {
    -moz-transform: scale(1.1);
  }
}
@keyframes grow {
  from {
    transform: scale(0.9);
  }
  to {
    transform: scale(1.1);
  }
}
