h1,
h2,
h3,
h4,
h5,
h6 {
  margin-block-start: 1em;
  margin-block-end: 1em;
  font-weight: 600;
  color: #202020;
}
img {
  vertical-align: initial;
}
body {
  margin: 0;
  padding: 0;
  font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
a:focus,
div:focus {
  outline: 0;
}
a {
  color: #106ba3;
}
a:hover {
  color: #0a4a72;
}
.dropping {
  border: 3px dashed #ccc;
}
.added td {
  color: green;
}
.removed td {
  color: red;
}
.sequoia-striped {
  line-height: 1.2em;
}
.sequoia-striped tbody tr:nth-child(even) {
  background: rgba(217, 217, 217, 0.2);
}
.sequoia-striped td.ant-table-column-sort {
  background: none;
}
@media print {
  @page {
    size: auto;
    /* auto is the initial value */
    margin-top: 0;
    /* Removes the http link, page numbers and some chrome formatting from the printed page */
    margin-bottom: 0;
  }
  td {
    box-shadow: none !important;
  }
  .ignore-print {
    display: none !important;
  }
}
body {
  color: #202020;
}
body ul.ant-timeline {
  color: #202020;
}
body div.ant-table {
  color: #202020;
}
body.theme-dark {
  color: #ececec;
}
body.theme-dark h1,
body.theme-dark h2,
body.theme-dark h3,
body.theme-dark h4,
body.theme-dark h5,
body.theme-dark h6 {
  color: #ececec;
}
body.theme-dark .ant-select.ant-select-allow-clear,
body.theme-dark .ant-select.ant-select-multiple,
body.theme-dark .ant-radio-group.ant-radio-group-solid,
body.theme-dark .ant-input,
body.theme-dark .ant-input-number-input,
body.theme-dark .ant-checkbox {
  background: #222425;
}
body.theme-dark ul.ant-timeline {
  color: #ececec;
}
body.theme-dark div.ant-table {
  color: #ececec;
}
body.theme-dark .sequoia-striped tbody tr:nth-child(even) {
  background: rgba(217, 217, 217, 0.05);
}
body.theme-dark .ant-input-affix-wrapper > input {
  background: transparent;
}
body.theme-dark .ql-toolbar.ql-snow {
  border: 1px solid #434343;
}
body.theme-dark .ql-container.ql-snow {
  border: 1px solid #434343;
}
.ant-radio-inner {
  border: 1px solid #cccccc;
}
.ant-table.ant-table-middle .ant-table-column-sorters {
  padding: 0;
}
.ant-table.ant-table-small .ant-table-column-sorters {
  padding: 0;
}
.ant-select.ant-select-sm:not(.ant-select-customize-input).ant-select-single.ant-select-show-arrow .ant-select-selection-item {
  padding-right: 18px;
}
.ant-select.ant-select-sm:not(.ant-select-customize-input).ant-select-single.ant-select-show-arrow .ant-select-arrow {
  margin-top: -8px;
  margin-right: -2px;
}
.ant-table-row-grayed {
  opacity: 0.4;
}
.ant-drawer {
  z-index: 998;
}
body .ant-table-wrapper .ant-spin-container > .ant-table-pagination.ant-pagination:first-child {
  margin: -5px 0 10px;
}
body {
  line-height: 18px;
}
body p {
  line-height: 20px;
}
body .ant-card,
body .ant-timeline,
body .ant-table {
  line-height: 18px;
}
.fade-in {
  animation: fadeIn ease 1.5s;
  -webkit-animation: fadeIn ease 1.5s;
  -moz-animation: fadeIn ease 1.5s;
  -o-animation: fadeIn ease 1.5s;
  -ms-animation: fadeIn ease 1.5s;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.ql-active .ql-stroke {
  fill: none;
  stroke: #48974c !important;
}
.quill > .ql-container > .ql-editor.ql-blank::before {
  color: #888888;
}
.mention {
  background-color: transparent;
}
.ql-editor .mention {
  background-color: #64646417;
}
.ql-editor .ql-mention-list-item {
  line-height: 28px;
  font-size: 14px;
  padding: 0 10px;
}
